<template>
  <div class="shadow w-100">
    <b-card style="border-radius: 10px; width: 100% !important;">
      <div class="d-flex">
        <div
          class="d-flex justify-content-center align-items-center flex-shrink-0"
          :style="'background-color:' + client.background"
          style="border-radius: 100%; height: 50px; width: 50px;"
        >
          <component
            :is="client.icon"
            class="h2 m-0"
          />
        </div>
        <div
          class="ml-2 w-100"
        >
          <h3>{{ client.title }}</h3>
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex ml-1">
              <h6>{{ $t('RefID') }}:</h6>
              &nbsp;
              <h6>{{ client.refID }}</h6>
            </div>
            <div class="d-flex">
              <h6>{{ $t('Lang') }}:</h6>
              &nbsp;
              <h6>{{ client.lang }}</h6>
            </div>

          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mt-1">
        <div
          class="d-flex align-items-center p-2"
          :style="'background-color:' + client.background"
          style="border-radius: 8px; height: 35px;"
        > <span
          class="p-1&quot;"
          style="color: black; font-size: 13px;"
          @click="onLinkClick"
        >{{ APP_URL+client.to.href }}</span>
        </div>
        <div
          class="d-flex align-items-center ml-1"
          @click="copyRefCode()"
        >
          <b-icon
            class="h3"
            icon="BIconBookmarks"
            style="cursor: pointer;"
          />
        </div>
      </div>

    </b-card>

  </div>
</template>
<script>
import {
  BCard, BAvatar, BIcon, BIconBookmarks, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BIcon,
    BIconBookmarks,
    BButton,
  },
  props: {
    client: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        icon: '',
        color: '',
        background: '',
        refID: '',
        lang: '',
      }),
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    APP_URL() {
      return process.env.VUE_APP_APP_URL
    },
  },
  methods: {
    copyRefCode() {
      const textToCopy = this.APP_URL + this.client.to.href
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$swal({
          title: this.$t('copied'),
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).catch(error => {
        console.error('Error copying text:', error)
      })
    },
    onLinkClick(event) {
      // Prevent default action
      event.preventDefault()

      // Clear any existing selections
      window.getSelection().removeAllRanges()

      // Create a new range
      const range = document.createRange()

      // Set the range to the contents of the target element
      range.selectNodeContents(event.target)

      // Add that range to the selection
      window.getSelection().addRange(range)
    },
  },

}
</script>
