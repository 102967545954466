<template>
  <div>
    <IbTabs />
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="9"
        >
          <b-alert
            class="p-2"
            show
            variant="warning"
          >
            <div class="d-sm-flex justify-content-sm-between align-items-sm-center">
              <p
                class="mb-1"
                style="font-weight: bold; "
              >
                {{ $t('ib-refferal-warning') }}
              </p>
              <b-button
                variant="warning"
                class="btn-tour-skip w-100 w-sm-auto"
                :to="{name:'profile-referral'}"
              >{{ $t('change-referral-code') }}</b-button>
            </div>
          </b-alert>
          <p>
            {{ $t('ib-refferal-desc') }}
          </p>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div>
            <b-form-group
              :label="$t('select-language')"
            >
              <v-select
                v-if="selectedLanguage"
                v-model="selectedLanguage"
                :options="languages"
                label="name"
                :clearable="false"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col
          v-for="detail in clients"
          :key="detail.id"
          lg="6"
          md="6"
          sm="6"
          class="px-1 w-100"
        >
          <referralCard
            :client="detail"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>

import {
  BRow, BCol, BIconCashCoin, BOverlay, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import referralCard from '@/modules/IB/views/components/referralCard.vue'
import IbTabs from './components/ibTabs.vue'
import PannelIbApis from '@/modules/IB/services/apis'

const referral = new PannelIbApis()
export default {
  components: {
    referralCard,
    BRow,
    BCol,
    IbTabs,
    BOverlay,
    vSelect,
    BAlert,
  },
  data() {
    return {
      show: false,
      refID: '',
      selectedLanguage: { locale: this.$i18n.locale, name: this.$t(this.$i18n.locales.find(loc => loc.locale === this.$i18n.locale).name) }, // Selected language
    }
  },
  computed: {
    languages() {
      return this.$i18n.locales.map(locale => ({ locale: locale.locale, name: this.$t(locale.name) })) // Available languages
    },
    clients() {
      return [
        {
          title: this.$t('forex-real-account'),
          text: 'fdsf',
          refID: this.refID,
          icon: BIconCashCoin,
          color: '#91CD86',
          lang: this.selectedLanguage?.name,
          to: this.$router.resolve({ name: 'register', params: { lang: this.selectedLanguage?.locale }, query: { refID: this.refID } }),
          background: '#C8DBFF',
        },
        {
          title: this.$t('forex-demo-account'),
          text: 'fdsf',
          refID: this.refID,
          icon: BIconCashCoin,
          color: '#91CD86',
          lang: this.selectedLanguage?.name,
          to: this.$router.resolve({ name: 'forexModule-create-demo-account', params: { lang: this.selectedLanguage?.locale }, query: { refID: this.refID } }),
          background: '#C8DBFF',
        },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await referral.referralCode().then(res => {
        this.refID = res.data.results.code
        this.show = false
      }).finally(() => {
        this.show = false
      })
    },
  },
}
</script>
